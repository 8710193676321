import { ErpTableOptions } from '@/types/type';
import { exportFile } from '@/utils/publicMethods';
import dayjs from 'dayjs';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const start = dayjs().subtract(6, 'month').format('YYYY-MM');
  const end = dayjs().format('YYYY-MM');
  const columnList: ErpTableOptions[] = [
    {
      label: '客户',
      prop: 'customerName', // 属性
      minWidth: 180,
      value: '',
      type: 'input',
    },
    {
      label: '应结算金额',
      prop: 'expectAmount',
      propDesc: 'expectAmountDesc',
      minWidth: 140,
      type: 'number',
      value: [],
      filterProp: ['expectAmountMinVal', 'expectAmountMaxVal'],
    },
    {
      label: '已回款金额',
      prop: 'refundAmount',
      propDesc: 'refundAmountDesc',
      minWidth: 140,
      type: 'number',
      value: [],
      filterProp: ['refundAmountMinVal', 'refundAmountMaxVal'],
    },
    {
      label: '未回款金额',
      prop: 'unpaidAmount',
      propDesc: 'unpaidAmountDesc',
      minWidth: 140,
      type: 'number',
      value: [],
      filterProp: ['unpaidAmountMinVal', 'unpaidAmountMaxVal'],
    },
    {
      label: '已开票金额',
      prop: 'invoicedAmount',
      propDesc: 'invoicedAmountDesc',
      minWidth: 140,
      type: 'number',
      value: [],
      filterProp: ['invoicedAmountMinVal', 'invoicedAmountMaxVal'],
    },
    {
      label: '不开票金额',
      prop: 'nonInvoicedAmount',
      propDesc: 'nonInvoicedAmountDesc',
      minWidth: 140,
      type: 'number',
      value: [],
      filterProp: ['nonInvoicedAmountMinVal', 'nonInvoicedAmountMaxVal'],
    },
    {
      label: '未开票金额',
      prop: 'notInvoicedAmount',
      propDesc: 'notInvoicedAmountDesc',
      minWidth: 140,
      type: 'number',
      value: [],
      filterProp: ['notInvoicedAmountMinVal', 'notInvoicedAmountMaxVal'],
    },
    {
      label: '时间范围',
      prop: 'timeScope',
      propDesc: 'timeScope',
      minWidth: 140,
      value: [start, end],
      isRequired: true,
      type: 'month',
      filterProp: ['startTime', 'endTime'],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 88,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button class="list_primary"  type="primary"
            plain
            v-if="hasPermission('erp:report:customer:exportCustomerReport')"
            @click='addContract(row)'>导出</el-button>
           
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const addContract = (row) => {
            exportFile({
              type: 'POST',
              url: '/malicrm/report/customer/exportCustomerReport',
              data: {
                customerId: row.customerId,
                endTime: Instance.setupState.tableRef.getQueryParams()?.endTime,
                startTime: Instance.setupState.tableRef.getQueryParams()
                  ?.startTime,
              },
            });
          };
          return { addContract };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
