
import { defineComponent, getCurrentInstance } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './useListData';
import { erpConfirm, errorMessage, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
export default defineComponent({
  name: 'customerParameter',
  components: { ErpList },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { startTimer, timeStatus } = useTimer();
    const { columnList, tableRef } = useListData(Instance);
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 预付导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/apply/pay/exportApplyPaymentRecord',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    // 查看详情
    const rowClick = () => {
      return true;
    };

    return {
      exportFileFn,
      columnList,
      tableRef,
      refreshTable,
      rowClick,
    };
  },
});
